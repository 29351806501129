import axios from "axios";
import { alertErrorMessage } from "../../customComponent/CustomAlertMessage";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import CryptoJS from 'crypto-js';


const getDeviceVisitorId = async () => {
  const SECRET_KEY = "b5bab58f2c29b_cv_trade_b560253293bd";
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  const encryptedId = CryptoJS.AES.encrypt(result.visitorId, SECRET_KEY).toString();
  return encryptedId;
};

export const ApiCallPost = async (url, parameters, headers) => {
  try {
    const response = await axios.post(url, parameters, { headers: headers });
    return response.data;
  } catch (error) {
    if (error.response.data.message === "Token is expired" || error.response.data.message === "Admin not found this email" || error.response.data.message === "Admin does not have access" || error.response.data.message === "Unauthorized Request!") {
      tokenExpire(error.response.data.message)
      return;
    }
    if (error.response.data.message === "IP not whitelisted for this signid") {
      ipNotWhitelisted(error.response.data.message)
      return;
    }
    return error.response.data;
  }
};

export const ApiCallDelete = async (url, parameters, headers) => {
  try {
    const response = await axios.delete(url, parameters, { headers: headers });
    return response.data;
  } catch (error) {
    if (error.response.data.message === "Token is expired" || error.response.data.message === "Admin not found this email" || error.response.data.message === "Admin does not have access" || error.response.data.message === "Unauthorized Request!") {
      tokenExpire(error.response.data.message)
      return;
    }
    if (error.response.data.message === "IP not whitelisted for this signid") {
      ipNotWhitelisted(error.response.data.message)
      return;
    }
    return error.response.data;
  }
};


export const ApiCallGet = async (url, headers) => {
  try {
    const response = await axios.get(url, { headers: headers });
    return response.data;

  } catch (error) {
    if (error.response.data.message === "Token is expired" || error.response.data.message === "Admin not found this email" || error.response.data.message === "Admin does not have access" || error.response.data.message === "Unauthorized Request!") {
      tokenExpire(error.response.data.message)
      return;
    }
    if (error.response.data.message === "IP not whitelisted for this signid") {
      ipNotWhitelisted(error.response.data.message)
      return;
    }
    return error.response.data;
  }
};

export const ApiCallPut = async (url, parameters, headers) => {
  try {
    const response = await axios.put(url, parameters, { headers: headers });
    return response.data;
  } catch (error) {
    if (error.response.data.message === "Token is expired" || error.response.data.message === "Admin not found this email" || error.response.data.message === "Admin does not have access" || error.response.data.message === "Unauthorized Request!") {
      tokenExpire(error.response.data.message)
      return;
    }
    if (error.response.data.message === "IP not whitelisted for this signid") {
      ipNotWhitelisted(error.response.data.message)
      return;
    }
    return error.response.data;
  }
};


const tokenExpire = (message) => {
  alertErrorMessage(message);
  sessionStorage.clear();
  window.location.reload();
};

const ipNotWhitelisted = (message) => {
  alertErrorMessage(message);
  sessionStorage.clear();
};