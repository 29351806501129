import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";

const UserDetailsPage = () => {
    const { userId } = useParams();
    const [userDetails, setUserDetails] = useState(null);

    const fetchData = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.completeDetailsUser(userId); // Replace with your actual service method
            if (result && result.success) {
                setUserDetails(result);
            } else {
                console.error("Failed to fetch user details");
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            LoaderHelper.loaderStatus(false);
        }
    };

    useEffect(() => {
        if (userId) fetchData();
    }, [userId]);

    //   if (isLoading) {
    //     return <LoaderHelper />;
    //   }
    const navigate = useNavigate()
    if (!userDetails) {
        return <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <button
                                    className="btn btn-light btn-icon"
                                    onClick={() => navigate(-1)} // Go back to the previous page
                                    style={{ marginRight: "10px" }}
                                    title="Go Back"
                                >
                                    <i className="fa fa-arrow-left"></i>
                                </button>
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">

                                        <h1 className="page-header-title d-inline-block">
                                            <div className="page-header-icon"><i className="fa fa-user"></i></div>
                                            User Details
                                        </h1>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        {/* User Information */}
                        <div className="card mb-4">
                            <div className="card-header">
                                <h4>No Data Found</h4>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    }

    const {
        userDetails: userInfo,
        walletTransactions = {}, // Provide default empty object
        userWallets = [],
        userOrderbook = [],
        depositTransactions = [],
        withdrawalTransactions = [],
        adminDebitTransactions = [],
        adminCreditTransactions = [],
        quickBuySellTransactions = [],
    } = userDetails;

    // Handle null or empty walletTransactions gracefully
    const {
        totalDepositTransactions = 0,
        totalWithdrawalTransactions = 0,
        totalAdminCreditTransactions = 0,
        totalAdminDebitTransactions = 0,
        totalDepositsByCurrency = [],
        totalWithdrawalsByCurrency = [],
    } = walletTransactions || {};

    // Table columns for wallet and orderbook data
    const walletColumns = [
        { name: "Currency", selector: (row) => row.short_name || "-", sortable: true },
        { name: "Balance", selector: (row) => row.balance?.toFixed(6) || 0, sortable: true },
        { name: "Locked Balance", selector: (row) => row.locked_balance?.toFixed(6) || 0, sortable: true },
    ];

    const orderbookColumns = [
        { name: "Sr no.", width: "100px", selector: (row, index) => userOrderbook?.indexOf(row) + 1, },
        { name: "Date/Time", width: "200px", selector: (row) => new Date(row.createdAt).toLocaleString() || "-", sortable: true },
        { name: "Order Type", selector: (row) => row.order_type || "-", },
        { name: "Pay Currency", selector: (row) => row?.pay_currency },
        { name: "Get Currency", selector: (row) => row?.ask_currency },
        { name: "Side", selector: (row) => <span className={`${row.side === "BUY" ? "text-success" : "text-danger"} `}>{row.side || "-"} </span> },
        { name: "Price", selector: (row) => row.price?.toFixed(6) || 0, sortable: true },
        { name: "Quantity", selector: (row) => row.quantity?.toFixed(6) || 0, sortable: true },
        { name: "Remaining", selector: (row) => row.remaining?.toFixed(6) || 0, sortable: true },
        { name: "Status", selector: (row) => row.status || "-", },
    ];

    const quickBuySellColumns = [
        { name: "Sr no.", width: "100px", selector: (row, index) => quickBuySellTransactions?.indexOf(row) + 1, },
        { name: "Date/Time", width: "200px", selector: (row) => new Date(row.updatedAt).toLocaleString() || "-", sortable: true },
        { name: "Pay Currency", selector: (row) => row?.from },
        { name: "Get Currency", selector: (row) => row?.to },
        { name: "Side", selector: (row) => <span className={`${row.side === "BUY" ? "text-success" : "text-danger"} `}>{row.side || "-"} </span> },
        { name: "Pay Amount", selector: (row) => row.pay_amount?.toFixed(6) || 0, sortable: true },
        { name: "Get Amount", selector: (row) => row.get_amount?.toFixed(6) || 0, sortable: true },
        { name: "Price", selector: (row) => row.price?.toFixed(6) || "N/A", sortable: true },
        { name: "Fee Percentage", selector: (row) => row.fee_percentage?.toFixed(6) || "N/A", sortable: true },
        { name: "Fee", selector: (row) => row.fee?.toFixed(6) || 0, sortable: true },
    ];

    const renderTransactions = (transactions, title) => (
        <div className="card mb-4">
            <div className="card-header">
                <h4>{title}</h4>
            </div>
            <div className="card-body">
                {transactions.length > 0 ? (
                    <ol>
                        {transactions.map((txn, index) => (
                            <React.Fragment key={index} >
                                <li >
                                    <strong>{txn.short_name}:</strong> {txn.amount} <br />
                                    <strong>Status:</strong> <span className={`text-${txn.status === "COMPLETE" ? "success" : txn.status === "REJECTED" ? "danger" : "warning"}`}> {txn.status || "N/A"} </span><br />
                                    <strong>Transaction Hash:</strong> {txn.transaction_hash || "N/A"} <br />
                                    <strong>From:</strong> {txn.from_address || "N/A"} <br />
                                    <strong>To:</strong> {txn.to_address || "N/A"} <br />
                                    <strong>Date:</strong> {new Date(txn.createdAt).toLocaleString()}
                                </li>
                                <hr />
                            </React.Fragment>
                        ))}
                    </ol>
                ) : (
                    <p>No {title.toLowerCase()} found.</p>
                )}
            </div>
        </div>
    );

    const renderAdminTransactions = (transactions, title) => (
        <div className="card mb-4">
            <div className="card-header">
                <h4>{title}</h4>
            </div>
            <div className="card-body">
                {transactions.length > 0 ? (
                    <ol>
                        {transactions.map((txn, index) => (
                            <React.Fragment key={index} >
                                <li >
                                    <strong>{txn.short_name}:</strong> {txn.amount} <br />
                                    <strong>Amount:</strong> {txn.amount || "N/A"} <br />
                                    <strong>Description:</strong> {txn.description || "N/A"} <br />
                                    <strong>Date:</strong> {new Date(txn.createdAt).toLocaleString()}
                                </li>
                                <hr />
                            </React.Fragment>
                        ))}
                    </ol>
                ) : (
                    <p>No {title.toLowerCase()} found.</p>
                )}
            </div>
        </div>
    );

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <button
                                className="btn btn-light btn-icon"
                                onClick={() => navigate(-1)} // Go back to the previous page
                                style={{ marginRight: "10px" }}
                                title="Go Back"
                            >
                                <i className="fa fa-arrow-left"></i>
                            </button>
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">

                                    <h1 className="page-header-title d-inline-block">
                                        <div className="page-header-icon"><i className="fa fa-user"></i></div>
                                        User Details
                                    </h1>
                                </div>
                            </div>

                        </div>
                    </div>
                </header>

                <div className="container-xl px-4 mt-n10">
                    {/* User Information */}
                    <div className="card mb-4">
                        <div className="card-header">
                            <h4>User Information</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <p><strong>User Id:</strong> {userId}</p>
                                    <p><strong>Name:</strong> {userInfo.firstName} {userInfo.lastName}</p>
                                    <p><strong>Email:</strong> {userInfo.emailId}</p>
                                    <p><strong>Mobile Number:</strong> {userInfo.mobileNumber}</p>

                                </div>
                                <div className="col-md-6">
                                    <p><strong>Registered Days:</strong> {userInfo.registeredDays}</p>
                                    <p>
                                        <strong>KYC Status:</strong>{" "}
                                        {userInfo.kycVerified === 0
                                            ? "Not Submitted"
                                            : userInfo.kycVerified === 1
                                                ? "Pending"
                                                : userInfo.kycVerified === 2
                                                    ? "Approved"
                                                    : "Rejected"}
                                    </p>
                                    <p><strong>Total Referrals:</strong> {userInfo.total_refer}</p>
                                    <p><strong>Status:</strong> {userInfo.status}</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* Wallet Transactions */}
                    <div className="card mb-4">
                        <div className="card-header">
                            <h4>Wallet Transactions Summary</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <p><strong>Total Deposits:</strong> {totalDepositTransactions}</p>
                                    <p><strong>Total Withdrawals:</strong> {totalWithdrawalTransactions}</p>
                                    <p><strong>Total Admin Credits:</strong> {totalAdminCreditTransactions}</p>
                                    <p><strong>Total Admin Debits:</strong> {totalAdminDebitTransactions}</p>
                                </div>
                                <div className="col-md-6">
                                    <h5>Total Deposits By Currency:</h5>
                                    <ul>
                                        {totalDepositsByCurrency.map((item, index) => (
                                            <li key={index}>
                                                {item.currency}: {item.amount}
                                            </li>
                                        ))}
                                    </ul>
                                    <h5>Total Withdrawals By Currency:</h5>
                                    <ul>
                                        {totalWithdrawalsByCurrency.map((item, index) => (
                                            <li key={index}>
                                                {item.currency}: {item.amount}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* User Wallets */}
                    <div className="card mb-4">
                        <div className="card-header">
                            <h4>User Wallets</h4>
                        </div>
                        <div className="card-body">
                            <DataTableBase columns={walletColumns} data={userWallets} pagination={true} />
                        </div>
                    </div>



                    {/* Deposit Transactions */}
                    {renderTransactions(depositTransactions, "Deposit Transactions")}

                    {/* Withdrawal Transactions */}
                    {renderTransactions(withdrawalTransactions, "Withdrawal Transactions")}

                    {renderAdminTransactions(adminCreditTransactions, "Admin Credit Transactions")}

                    {renderAdminTransactions(adminDebitTransactions, "Admin Debit Transactions")}


                    {/* User Orderbook */}
                    <div className="card mb-4">
                        <div className="card-header">
                            <h4>User Orderbook</h4>
                        </div>
                        <div className="card-body">
                            <DataTableBase columns={orderbookColumns} data={userOrderbook?.reverse()} pagination={true} />
                        </div>
                    </div>
                    <div className="card mb-4">
                        <div className="card-header">
                            <h4>User Quick Buy Sell</h4>
                        </div>
                        <div className="card-body">
                            <DataTableBase columns={quickBuySellColumns} data={quickBuySellTransactions?.reverse()} pagination={true} />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default UserDetailsPage;
